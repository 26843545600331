<template>
  <v-container>
    <v-card class="pa-2">
      <v-card-title> SMS Buying History </v-card-title>
      <v-row>
        <v-col cols="12">
          <!-- Data table to display the SMS packages -->
          <v-data-table
            show-select
            single-select
            :items="packages"
            :headers="headers"
            v-model="selected_fees"
            :type="'radio'"
          >
          </v-data-table>
        </v-col>
      </v-row>

      <v-divider class="my-4"></v-divider>

      <!-- Display the total payable amount, bank fee, and VAT -->
      <v-row justify="end">
        <v-col cols="12" md="5" order="last">
          <p class="">
            Due :
            <strong>{{ total_amount.toFixed(2) }} Taka</strong>
          </p>

          <p class="">
            15% VAT:
            <strong>{{ vat.toFixed(2) }} Taka</strong>
          </p>
          <!-- <p class="">
            2.5% Bank charge :
            <strong>{{ bank_fee.toFixed(2) }} Taka</strong>
          </p> -->
          <p class="" style="color: mediumspringgreen">
            Total Payable :
            <strong>{{ total_payable.toFixed(2) }} Taka</strong>
          </p>
        </v-col>
      </v-row>

      <v-card-actions class="d-flex justify-end">
        <v-btn
          width="40%"
          color="primary"
          @click="pay"
          v-if="location.country==='Bangladesh'"

          :disabled="selected_fees.length < 1"
        >
          Pay Now
        </v-btn>
        <v-btn
          width="40%"
          color="primary"
          @click="payStripe"
          v-else

          :disabled="selected_fees.length < 1"
        >
          Pay 
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import aamarpay from "../../../components/payment/aamarpay.vue";
export default {
  components: { aamarpay },
  props: [],
  data() {
    return {
      packages: [],
      selectedOption: null,
      payable_fees: [],
      selected_fees: [],
      payment_url: "",
     
      headers: [
        { text: "Purcahse  ", value: "name" },
        { text: "Amount (TK)", value: "price" },
        { text: "SMS Qty", value: "quantity" },
      ],
      location: {
      latitude: null,
      longitude: null,
      country: null,
    },
    };
    
  },
  computed: {
    total_amount() {
      return this.selected_fees.reduce(
        (total, fee) => total + Number(fee.price),
        0
      );
    },
    bank_fee() {
      return this.total_amount * 0.025;
    },
    vat() {
      return this.total_amount * 0.15;
    },
    total_payable() {
      return this.total_amount + this.vat;
    },
  },

  mounted() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          const apiUrl = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`;
          fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
              const countryName = data.address.country;
              this.location = {
                latitude,
                longitude,
                country: countryName,
              };
            });
        },
        (error) => {
          console.error(error.message);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
    this.getPayableFees();
    this.getSMSPack();
    this.stripe = Stripe('pk_live_51NIBvqLKea0AuRCN80F53ZOp76t2fgJpvEHJOLTrRe4nXUoQCI4libCmtJblc9POJ9PuEFkxSB42tNlSX0vtttZB00QIXEU1f1');

  },

  methods: {
    getSMSPack() {
      this.$api.get("sms_package/").then((res) => {
        this.packages = res.data.results;
      });
    },

    getPayableFees() {
      return this.$api
        .get("sms_payment/payable/")
        .then((result) => {
          this.payable_fees = result.data;
        })
        .catch(() => {});
    },

    async payStripe() {
    const selected_fees_ids = this.selected_fees.map((fee) => fee.price);
    const amount = this.total_payable.toFixed(2);
    const package_name = this.selected_fees.length > 0 ? this.selected_fees[0].name : '';
    const quantity =this.selected_fees.map((q) => q.quantity.toString());


    try {
      const response = await this.$api.post("/stripe_payment/pay_stripe/", {
        sms_fee: selected_fees_ids,
        amount: amount,
        package_name: package_name,
        quantity:quantity.join(',')
      });
      
      const { checkout_url } = response.data;

      const popup = window.open(
        checkout_url,
        "StripeCheckout",
        "width=600,height=700,top=1200,left=650,toolbar=no,menubar=no,scrollbars=no,resizable=no,location=no"
      );
      
      const interval = setInterval(() => {
        if (popup.closed) {
          clearInterval(interval);
    
        }
      }, 1000);
    } catch (error) {
      console.error('Error creating Stripe checkout session:', error);
    }
},


    pay() {
      const selected_fees_ids = this.selected_fees.map((fee) => fee.price);
      const data = {
        sms_fee: selected_fees_ids,
        amount: this.total_payable.toFixed(2),
      };
      this.$api
        .post("/sms_payment/pay/", data)
        .then((res) => {
          console.log(res.data.payment_url);
          const popup = window.open(
            res.data.payment_url,
            "payment",
            "width=600,height=700,top=1200,left=650, toolbar=no, menubar=no,scrollbars=no,resizable=no,location=no,"
          );
          const interval = setInterval(() => {
            if (popup.closed) {
              clearInterval(interval);
              this.getPayableFees();
              this.$store.dispatch("sms_payment");
              this.selected_fees = [];
            }
          }, 1000);
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    },
  },
};
</script>
  
  <style>
</style>
  